import React, { useCallback, useReducer } from 'react';
import LoadingContext from '@/contexts/Loading';

interface State {
  loadingCount: number;
}

interface Props {
  children: React.ReactNode;
}

const initialState: State = { loadingCount: 0 };

function reducer(state: State, action: { type: string }) {
  switch (action.type) {
    case 'showLoading':
      return { loadingCount: state.loadingCount + 1 };
    case 'hideLoading': {
      let loadingCount = state.loadingCount - 1;

      if (loadingCount < 0) {
        loadingCount = 0;
      }

      return { loadingCount };
    }
    default:
      return initialState;
  }
}

const LoadingProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showLoading = useCallback(() => {
    dispatch({ type: 'showLoading' });
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch({ type: 'hideLoading' });
  }, [dispatch]);

  return (
    <LoadingContext.Provider
      value={{ isLoading: state.loadingCount !== 0, showLoading, hideLoading }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
