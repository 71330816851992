import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import AuthContext from '@/contexts/Auth';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Route path={props.path}>
      {isLoggedIn ? props.children : <Redirect to="/login" />}
    </Route>
  );
};

export default PrivateRoute;
