import { useEffect, useContext, useState, useCallback } from 'react';
import MutationContext from '@/contexts/Mutation';
import AuthContext from '@/contexts/Auth';
import LoadingContext from '@/contexts/Loading';
import NotificationContext from '@/contexts/Notification';
import Error401 from '@/errors/Error401';

interface HookData {
  isLoading: boolean;
  error?: Error;
  mutateApi(
    data: any,
    url?: string
  ): Promise<{ status: boolean; data: any } | undefined>;
}

const useMutateApi = (
  url: string,
  method?: 'POST' | 'PUT' | 'DELETE',
  hasLoading = true,
  isAuthApi = true
): HookData => {
  const [isLoading, setLoading] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>();
  const { mutate } = useContext(MutationContext);
  const { logout } = useContext(AuthContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showNotification } = useContext(NotificationContext);

  const mutateApi = useCallback(
    async (
      data: any,
      overwriteUrl = ''
    ): Promise<{ status: boolean; data: any } | undefined> => {
      setLoading(true);
      setError(undefined);

      let res;
      try {
        console.log('before calling api', url)
        res = await mutate(overwriteUrl || url, data, method);
        console.log(res)

        if (res?.status && res?.redirect) {
          window.location.href = res?.redirect;
        }

        if (res?.status === false) {
          setError(new Error(res?.message))
        }
      } catch (e: any) {
        if (e?.response?.status === 401) {
          setError(new Error401('401'));
        } else {
          setError(e);
        }
      }

      setLoading(false);

      return res;
    },
    [mutate, setLoading, url, method]
  );

  useEffect(() => {
    if (hasLoading) {
      if (isLoading) {
        showLoading();
      } else if (isLoading === false) {
        hideLoading();

        setLoading(undefined);
      }
    }
  }, [isLoading, showLoading, hideLoading, hasLoading]);

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line
      // @ts-ignore
      if (error?.response?.status) {
        // eslint-disable-next-line
          // @ts-ignore
        if (isAuthApi && error?.response?.status === 401) {
          if (typeof logout === 'function') {
            logout();
          }
          return;
        }

        showNotification(
          'error',
          // eslint-disable-next-line
          // @ts-ignore
          `An error occurred while calling API: ${error?.response?.status} ${url}.`
        );
      } else {
        showNotification(
          'error',
          `An error occurred while calling API: ${url} ${error.toString()}`
        );
      }
    }
  }, [error, isAuthApi, logout, showNotification, url]);

  return {
    isLoading: !!isLoading,
    error,
    mutateApi,
  };
};

export default useMutateApi;
