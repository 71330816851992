import React from 'react';

interface ContextProps {
  mutate: (url: string, data: any, method?: 'POST' | 'PUT' | 'DELETE') => any;
}

const MutationContext = React.createContext<ContextProps>({
  mutate: () => {},
});

export default MutationContext;
