import fetch from 'unfetch';
import Error401 from '@/errors/Error401';

const fetcher = async (url: string) => {
  const res = await fetch(url);

  if (!res.ok) {
    let error: Error;
    if (res.status === 401) {
      error = new Error401('401');
    } else {
      error = new Error(
        `An error occurred while calling API: ${res.status} ${url}.`
      );
    }

    throw error;
  }

  return res.json();
};

export default fetcher;
