import React, { useCallback } from 'react';
import NotificationContext, { NotificationType } from '@/contexts/Notification';
import { toast } from 'react-toastify';

interface Props {
  children: React.ReactNode;
}

const NotificationProvider: React.FC<Props> = ({ children }: Props) => {
  const showNotification = useCallback(
    (type: NotificationType, message: string) => {
      toast[type](message);
    },
    []
  );

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
