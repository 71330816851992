import React from 'react';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '@/store';
import translations from '@shopify/polaris/locales/en.json';
import Auth from '@/components/Auth';
import LoadingProvider from '@/components/LoadingProvider';
import NotificationProvider from '@/components/NotificationProvider';
import MutationProvider from '@/components/MutationProvider';
import { SWRConfig } from 'swr';
import fetcher from '@/utils/fetcher';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import Routes from '@/Routes';

const theme = {
  colors: {
    topBar: {
      background: '#fff',
      backgroundLighter: '#F4F6F8',
      backgroundDarker: '#DFE3E8',
      border: '#C4CDD5',
      color: '#212B36',
    },
  },
  logo: {
    width: 200,
    url: '/',
    accessibilityLabel: 'Scrolly Telling',
  },
};

// TODO: Improve error handler
const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <Provider store={store}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <SWRConfig
            value={{
              fetcher,
            }}
          >
            <AppProvider theme={theme} i18n={translations}>
              <LoadingProvider>
                <NotificationProvider>
                  <MutationProvider>
                    <Auth>
                      <Routes />
                    </Auth>
                  </MutationProvider>
                </NotificationProvider>
              </LoadingProvider>
            </AppProvider>
          </SWRConfig>
        </ErrorBoundary>
      </Provider>
    </Router>
  );
};

export default App;
