import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '@/contexts/Auth';

interface Props {
  path: string;
  children: React.ReactNode;
}

const PublicRoute: React.FC<Props> = (props) => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Route path={props.path}>
      {props.children}
      {/* {isLoggedIn ? <Redirect to={'/'} /> : props.children} */}
    </Route>
  );
};

export default PublicRoute;
