import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {debug: true}); 

export default function pushEvent(name: string, data: any = {}) {
  if (typeof (window as any).dataLayer !== 'undefined') {
    (window as any).dataLayer.push({'event': name, ...data});
  }

  try {
    mixpanel.track(name, data);
  } catch (e) {}
}