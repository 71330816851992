import * as React from 'react';
import useApi from '@/hooks/useApi';
import { Shop, Subscription } from '@/types/shop';
import AuthContext from '@/contexts/Auth';

interface HookData {
  isLoading: boolean;
  shop: Shop;
  mutate(): void;
}

export interface Response {
  status: boolean;
  data: Data;
}

export interface Data {
  created: string;
  subscription: Subscription;
  modified: string;
  name: string;
  partnerDevelopment: boolean;
  email: string;
  id: string;
  type: string;
}

const useApiShop = (): HookData => {
  const { isLoggedIn } = React.useContext(AuthContext);
  const { res, error, isLoading, mutate } = useApi(
    isLoggedIn ? '/api/shop' : null
  );
  const resData = <Response>res;

  const shop = React.useMemo(() => {
    if (isLoading || error || !res) {
      return {
        id: '',
        email: '',
        type: 'SHOP',
        name: '',
      };
    }

    return <Shop>resData.data;
  }, [isLoading, error, res, resData]);

  return {
    isLoading,
    shop,
    mutate,
  };
};

export default useApiShop;
