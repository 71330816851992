import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import PrivateRoute from '@/components/PrivateRoute';
import PublicRoute from '@/components/PublicRoute';
import DefaultLayout from '@/layouts/Default/Default';
import EmptyLayout from '@/layouts/Empty/Empty';

const Home = loadable(() => import('@/pages/Home/Home'));
const Settings = loadable(() => import('@/pages/Settings/Settings'));
const CanvasList = loadable(() => import('@/pages/canvas/List/List'));
const CanvasCreation = loadable(
  () => import('@/pages/canvas/Creation/Creation')
);
const CanvasEdit = loadable(() => import('@/pages/canvas/Edit/Edit'));
const Login = loadable(() => import('@/pages/Login/Login'));
const LoginAsShop = loadable(() => import('@/pages/LoginAsShop/LoginAsShop'));
const PrivacyPolicy = loadable(
  () => import('@/pages/PrivacyPolicy/PrivacyPolicy')
);
const Templates = loadable(() => import('@/pages/Templates/Templates'));
const Demo = loadable(() => import('@/pages/Demo/Demo'));
const Preview = loadable(() => import('@/pages/Preview/Preview'));
const NotFound = loadable(() => import('@/pages/NotFound/NotFound'));

const Routes: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/">
        <DefaultLayout>
          <Home />
        </DefaultLayout>
      </PrivateRoute>
      <PrivateRoute exact path="/settings">
        <DefaultLayout>
          <Settings />
        </DefaultLayout>
      </PrivateRoute>
      <PrivateRoute exact path="/canvases">
        <DefaultLayout>
          <CanvasList />
        </DefaultLayout>
      </PrivateRoute>
      <PrivateRoute path="/canvases/create">
        <EmptyLayout>
          <CanvasCreation />
        </EmptyLayout>
      </PrivateRoute>
      <PrivateRoute path="/canvases/edit/:id">
        <EmptyLayout>
          <CanvasEdit />
        </EmptyLayout>
      </PrivateRoute>
      <PrivateRoute path="/templates">
        <DefaultLayout>
          <Templates />
        </DefaultLayout>
      </PrivateRoute>
      <PublicRoute path="/login-as-shop">
        <DefaultLayout>
          <LoginAsShop />
        </DefaultLayout>
      </PublicRoute>
      <PublicRoute path="/login">
        <DefaultLayout>
          <Login />
        </DefaultLayout>
      </PublicRoute>
      <PublicRoute path="/privacy-policy">
        <DefaultLayout>
          <PrivacyPolicy />
        </DefaultLayout>
      </PublicRoute>
      <PublicRoute path="/demo">
        <Demo />
      </PublicRoute>
      <PublicRoute path="/preview/:shopId/:canvasId">
        <Preview />
      </PublicRoute>
      <Route path="*">
        <DefaultLayout>
          <NotFound />
        </DefaultLayout>
      </Route>
    </Switch>
  );
};

export default Routes;
