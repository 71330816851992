import { combineReducers } from 'redux';
import canvas from './canvas/index';

export interface CanvasEditorUiState {
  settingTab: string;
}

export interface CanvasEntitiesState {
  present: any;
}

export interface CanvasEditorState {
  ui: CanvasEditorUiState;
  entities: CanvasEntitiesState;
}

export interface CanvasState {
  editor: CanvasEditorState;
}

export interface RootState {
  canvas: CanvasState;
}

export default combineReducers({
  canvas,
});
