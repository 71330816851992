export const LAYER_TYPES = {
  TEXT: {
    id: 'text',
    name: 'Text',
    key: 'TEXT',
  },
  IMAGE: {
    id: 'image',
    name: 'Image',
    key: 'IMAGE',
  },
  SHAPE: {
    id: 'shape',
    name: 'Shape',
    key: 'SHAPE',
  },
  IMAGE_SEQUENCE: {
    id: 'image_sequence',
    name: 'Image Sequence',
    key: 'IMAGE_SEQUENCE',
  },
  SVG: {
    id: 'svg',
    name: 'SVG',
    key: 'SVG',
  },
}

export const DEVICES = {
  DESKTOP: 'desktop',
  LAPTOP: 'laptop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
}

export const DEVICE_TITLES = {
  desktop: 'Desktop',
  laptop: 'Laptop',
  tablet: 'Tablet',
  mobile: 'Mobile',
}

export const CSS_DEVICE_MAX_WIDTHS = {
  [DEVICES.DESKTOP]: '100%',
  [DEVICES.LAPTOP]: '1024px',
  [DEVICES.TABLET]: '768px',
  [DEVICES.MOBILE]: '375px',
}

export const PERCENT_UNIT = 10 // 1% = 10px
export const SECOND_UNIT = 100 // 1s = 100px

export const SCENE_LAYOUTS = {
  FIXED: 'fixed',
  FULL_SCREEN: 'full-screen',
}

export const ANIMATIONS = {
  STYLES: 'styles',
  PIN: 'pin',
  IMAGE_SEQUENCE: 'image_sequence',
  DRAW_SVG: 'draw_svg',
}

export const ANIMATION_NAMES = {
  [ANIMATIONS.STYLES]: 'Styles Animation',
  [ANIMATIONS.PIN]: 'Pin (only for scene)',
  [ANIMATIONS.IMAGE_SEQUENCE]: 'Image Sequence',
  [ANIMATIONS.DRAW_SVG]: 'Draw SVG',
}

export const LAYER_TYPE_ANIMATIONS = {
  [LAYER_TYPES.TEXT.id]: [ANIMATIONS.STYLES],
  [LAYER_TYPES.IMAGE.id]: [ANIMATIONS.STYLES],
  [LAYER_TYPES.SHAPE.id]: [ANIMATIONS.STYLES],
  [LAYER_TYPES.IMAGE_SEQUENCE.id]: [
    ANIMATIONS.STYLES,
    ANIMATIONS.IMAGE_SEQUENCE,
  ],
  [LAYER_TYPES.SVG.id]: [ANIMATIONS.STYLES],
}

export const SCENE_ANIMATIONS = [ANIMATIONS.STYLES, ANIMATIONS.PIN]

export const SETTING_TABS = {
  CANVAS: 'canvas',
  SCENE: 'scene',
  LAYER: 'layer',
}

export const LAYER_TABS = {
  CONTENT: 'content',
  INITIAL: 'initial',
  ANIMATION: 'animation',
  HOVER: 'hover',
}
