import { useContext, useEffect } from 'react';
import useSWR from 'swr';
import AuthContext from '@/contexts/Auth';
import LoadingContext from '@/contexts/Loading';
import NotificationContext from '@/contexts/Notification';
import Error401 from '@/errors/Error401';

interface Res {
  status: boolean;
  data: unknown;
}

interface HookData {
  isLoading: boolean;
  res?: Res;
  error?: Error;
  mutate(): void;
}

const useApi = (
  url: string | null,
  hasLoading = true,
  isAuthApi = true
): HookData => {
  const { logout } = useContext(AuthContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showNotification } = useContext(NotificationContext);

  const {
    data: res,
    error,
    mutate,
  }: { data?: Res; error?: Error; mutate(): void } = useSWR(url, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const isLoading: boolean = !error && !res;

  useEffect(() => {
    if (hasLoading && url) {
      if (isLoading) {
        showLoading();
      } else {
        hideLoading();
      }
    }
  }, [isLoading, showLoading, hideLoading, hasLoading, url]);

  useEffect(() => {
    if (error) {
      if (isAuthApi && error && error instanceof Error401) {
        if (typeof logout === 'function') {
          logout();
        }
        return;
      }

      showNotification('error', error.toString());
    }
  }, [error, isAuthApi, logout, showNotification]);

  return {
    isLoading,
    res,
    mutate,
  };
};

export default useApi;
