import React, { useCallback, useState, useContext } from 'react';
import { Frame, Toast } from '@shopify/polaris';
import AuthContext from '@/contexts/Auth';
import LoadingContext from '@/contexts/Loading';
import Topbar from '@/layouts/Default/Topbar';
import Menu from '@/layouts/Default/Menu';
import PageLoading from '@/components/PageLoading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  children: React.ReactNode;
}

export default function (props: Props) {
  const { isLoggedIn } = useContext(AuthContext);
  const { isLoading } = useContext(LoadingContext);

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  return (
    <Frame
      topBar={<Topbar />}
      navigation={isLoggedIn ? <Menu /> : null}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
    >
      {isLoading && <PageLoading />}
      {props.children}
      <ToastContainer position="bottom-center" hideProgressBar />
    </Frame>
  );
}
