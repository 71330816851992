import React, { useCallback, useState, useContext } from 'react';
import { Icon, TopBar } from '@shopify/polaris';
import { ArrowLeftMinor, QuestionMarkMinor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import AuthContext from '@/contexts/Auth';
import useApiShop from '@/hooks/shop/useApiShop';
import PurchaseAction from './Topbar/PurchaseAction';

const Topbar: React.FC = () => {
  const history = useHistory();
  const { isLoggedIn, logout } = useContext(AuthContext);

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const { shop } = useApiShop();

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            { icon: ArrowLeftMinor, content: 'Logout', onAction: logout },
          ],
        },
      ]}
      name={shop?.name}
      detail={shop?.id}
      initials={shop?.name[0]}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);

  const toggleIsSecondaryMenuOpen = useCallback(
    () => setIsSecondaryMenuOpen((isSecondaryMenuOpen) => !isSecondaryMenuOpen),
    []
  );

  const secondaryMenuMarkup = (
    <TopBar.Menu
      activatorContent={
        <span className="flex items-center">
          <Icon source={QuestionMarkMinor} />
          <span className="ml-4">Documentation &amp; Support</span>
        </span>
      }
      open={isSecondaryMenuOpen}
      onOpen={toggleIsSecondaryMenuOpen}
      onClose={toggleIsSecondaryMenuOpen}
      actions={[
        {
          items: [
            {
              content: 'Documentation',
              onAction: () =>
                window.open('https://scrollytelling-docs.lamqsolutions.com'),
            },
            {
              content: 'Support',
              onAction: () => window.open('mailto:lamqsolutions.dev@gmail.com'),
            },
          ],
        },
      ]}
    />
  );

  // TODO: Show badge trial

  return (
    <TopBar
      showNavigationToggle
      searchField={<PurchaseAction />}
      userMenu={isLoggedIn && userMenuMarkup}
      secondaryMenu={secondaryMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );
};

export default Topbar;
