import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import dotProp from 'dot-prop-immutable';

export const UPDATE_ENTITIES = 'canvas/editor/entities/UPDATE_ENTITIES';
export const UPDATE = 'canvas/editor/entities/UPDATE';
export const COPY_TO_DEVICE = 'canvas/editor/entities/COPY_TO_DEVICE';
export const ADD_SCENE = 'canvas/editor/entities/ADD_SCENE';
export const ADD_LAYER = 'canvas/editor/entities/ADD_LAYER';
export const DELETE_LAYER = 'canvas/editor/entities/DELETE_LAYER';
export const TOGGLE_LAYER = 'canvas/editor/entities/TOGGLE_LAYER';
export const ADD_ANIMATION = 'canvas/editor/entities/ADD_ANIMATION';
export const DELETE_ANIMATION = 'canvas/editor/entities/DELETE_ANIMATION';
export const RESET = 'canvas/editor/entities/RESET';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ENTITIES: {
      const newState = cloneDeep(action.entities);

      if (typeof newState?.layers === 'undefined') {
        newState.layers = {};
      }

      return newState;
    }
    case UPDATE: {
      return dotProp.set(state, action.path, action.value);
    }
    case COPY_TO_DEVICE: {
      let newState = state;
      const { device } = action;

      // Scene
      if (!isEmpty(state.scenes)) {
        for (const id in state.scenes) {
          if (typeof state.scenes[id].devices[device] === 'undefined') {
            const { desktop } = state.scenes[id].devices;

            newState = dotProp.set(
              newState,
              `scenes.${id}.devices.${device}`,
              desktop
            );
          }
        }
      }

      // Layer
      if (!isEmpty(state.layers)) {
        for (const id in state.layers) {
          if (typeof state.layers[id].devices[device] === 'undefined') {
            const { desktop } = state.layers[id].devices;

            newState = dotProp.set(
              newState,
              `layers.${id}.devices.${device}`,
              desktop
            );
          }
        }
      }

      return newState;
    }
    case ADD_SCENE: {
      // If this is a duplicate action by undo/redo, so we will ignore this action
      if (state.scenes[action.scene.id]) {
        return state;
      }

      let newState = dotProp.set(
        state,
        `scenes.${action.scene.id}`,
        action.scene
      );

      // Add scene id to canvas
      const { scenes } = state.canvases[action.canvasId];
      newState = dotProp.set(newState, `canvases.${action.canvasId}.scenes`, [
        ...scenes,
        action.scene.id,
      ]);

      return newState;
    }
    case ADD_LAYER: {
      // If this is a duplicate action by undo/redo, so we will ignore this action
      if (state.layers[action.layer.id]) {
        return state;
      }

      let newState = dotProp.set(
        state,
        `layers.${action.layer.id}`,
        action.layer
      );

      // Add layer id to scene
      const { layers } = state.scenes[action.sceneId];
      newState = dotProp.set(newState, `scenes.${action.sceneId}.layers`, [
        ...layers,
        action.layer.id,
      ]);

      return newState;
    }
    case DELETE_LAYER: {
      let newState = dotProp.delete(state, `layers.${action.id}`);

      const layerIndex = state.scenes[action.sceneId].layers.indexOf(action.id);
      if (layerIndex > -1) {
        newState = dotProp.delete(
          newState,
          `scenes.${action.sceneId}.layers.${layerIndex}`
        );
      }

      return newState;
    }
    case TOGGLE_LAYER: {
      return dotProp.set(
        state,
        `layers.${action.id}.devices.${action.device}.visibility`,
        action.visibility
      );
    }
    case DELETE_ANIMATION: {
      return dotProp.delete(
        state,
        `${action.elementType}s.${action.id}.devices.${action.device}.animations.${action.index}`
      );
    }
    case ADD_ANIMATION: {
      let newState = state;

      if (action.elementType === 'layer') {
        newState = dotProp.merge(
          state,
          `layers.${action.id}.devices.${action.device}.animations`,
          [action.animation]
        );
      } else if (action.elementType === 'scene') {
        newState = dotProp.merge(
          state,
          `scenes.${action.id}.devices.${action.device}.animations`,
          [action.animation]
        );
      }

      return newState;
    }
    case RESET:
      return cloneDeep(initialState);
    default:
      return state;
  }
}

export function updateEntities(entities) {
  return { type: UPDATE_ENTITIES, entities };
}

export function update(path, value) {
  return { type: UPDATE, path, value };
}

export function copyToDevice(device) {
  return { type: COPY_TO_DEVICE, device };
}

export function addScene(canvasId, scene) {
  return { type: ADD_SCENE, scene, canvasId };
}

export function addLayer(sceneId, layer) {
  return { type: ADD_LAYER, layer, sceneId };
}

export function deleteLayer(sceneId, id) {
  return { type: DELETE_LAYER, sceneId, id };
}

export function toggleLayer(device, id, visibility) {
  return { type: TOGGLE_LAYER, device, id, visibility };
}

export function addAnimation(elementType, id, device, animation) {
  return { type: ADD_ANIMATION, elementType, id, device, animation };
}

export function deleteAnimation(elementType, id, device, index) {
  return { type: DELETE_ANIMATION, elementType, id, device, index };
}

export function reset() {
  return { type: RESET };
}
