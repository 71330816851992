import React, { useState } from 'react';
import Cookie from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { LOGGED_IN_COOKIE } from '@/constants/app';
import AuthContext from '@/contexts/Auth';

interface Props {
  children: React.ReactNode;
}

const Auth: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(!!Cookie.get(LOGGED_IN_COOKIE));

  const logout = React.useCallback(() => {
    Cookie.set(LOGGED_IN_COOKIE, '');
    setIsLoggedIn(false);
    history.push('/login');
  }, [setIsLoggedIn, history]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default Auth;
