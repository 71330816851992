import React, { useContext } from 'react';
import LoadingContext from '@/contexts/Loading';
import { Spinner } from '@shopify/polaris';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  children: React.ReactNode;
}

const EmptyLayout: React.FC<Props> = ({ children }: Props) => {
  const { isLoading } = useContext(LoadingContext);
  // TODO: Add loading component

  return (
    <>
      {children}
      <ToastContainer position="bottom-center" hideProgressBar />
      {isLoading && (
        <div className="fixed left-0 top-0 z-50 w-full h-full">
          <div className="xy-center" style={{ width: '50px', height: '50px' }}>
            <Spinner size="large" color="teal" />
          </div>
        </div>
      )}
    </>
  );
};

export default EmptyLayout;
