import React from 'react';

interface ContextProps {
  isLoggedIn: boolean;
  logout: () => void;
}

const AuthContext = React.createContext<ContextProps>({
  isLoggedIn: false,
  logout: () => {},
});

export default AuthContext;
