import { UPDATE_ENTITIES } from '../modules/canvas/editor/entities';
import { setChanged as setChangedAction } from '../modules/canvas/editor/ui';
import store from '../index';

const IGNORE_TIME = 250;

let filter = true;
export default function undoFilter(action, currState, prevState) {
  // other filters
  filter = actionsThrottlingFilter(action);
  return filter;
}

// Store rapid actions of the same type at most once every x time.
let timer;
function actionsThrottlingFilter(action) {
  clearTimeout(timer);
  timer = setTimeout(() => {
    store.dispatch({
      ...action,
      __lastFilter: true,
    });

    if (
      action.type.includes('canvas/editor/entities/') &&
      action.type !== UPDATE_ENTITIES
    ) {
      store.dispatch(setChangedAction(true));
    }
  }, IGNORE_TIME);

  if (action.__lastFilter) {
    clearTimeout(timer);
    return true;
  }

  return false;
}
