import React, { useEffect, useRef } from 'react';
import { Navigation } from '@shopify/polaris';
import {
  ArrowLeftMinor,
  HomeMajorMonotone,
  SettingsMinor,
  ImageWithTextOverlayMajorMonotone,
  ListMajorMonotone,
  VocabularyMajorMonotone,
  QuestionMarkMajorMonotone,
} from '@shopify/polaris-icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useApiShop from '@/hooks/shop/useApiShop';

const Menu: React.FC = () => {
  const history = useHistory();
  const homeMatch = useRouteMatch({
    path: '/',
    strict: true,
  });
  const settingsMatch = useRouteMatch('/settings');
  const canvasesMatch = useRouteMatch('/canvases');
  const templatesMatch = useRouteMatch('/templates');

  console.log(canvasesMatch, !!templatesMatch);

  // Disable default behavior of menu element
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current
        .querySelectorAll(
          '.Polaris-Navigation__Section:nth-child(2) a.Polaris-Navigation__Item'
        )
        .forEach((linkEl) => {
          linkEl.addEventListener('click', (evt) => evt.preventDefault());
        });
    }
  }, [ref]);

  const { shop } = useApiShop();

  return (
    <div ref={ref}>
      <Navigation location="/">
        <Navigation.Section
          items={[
            {
              url: `https://${shop.id}/admin/apps`,
              label: 'Back to Shopify',
              icon: ArrowLeftMinor,
            },
          ]}
        />
        <Navigation.Section
          separator
          title="Scrolly Telling App"
          items={[
            {
              label: 'Dashboard',
              onClick: () => history.push('/'),
              selected: !!homeMatch && homeMatch.isExact,
              url: '/',
              icon: HomeMajorMonotone,
            },
            {
              url: '/canvases',
              label: 'Canvases',
              onClick: () => history.push('/canvases'),
              icon: ImageWithTextOverlayMajorMonotone,
              selected: !!canvasesMatch,
            },
            {
              url: '/settings',
              selected: !!settingsMatch,
              label: 'Settings',
              onClick: () => {
                history.push('/settings');
                return false;
              },
              icon: SettingsMinor,
            },
            {
              label: 'Templates',
              url: '/templates',
              selected: !!templatesMatch,
              onClick: () => history.push('/templates'),
              icon: ListMajorMonotone,
            },
            {
              label: 'Documentation',
              onClick: () =>
                window.open('https://scrollytelling-docs.lamqsolutions.com'),
              icon: VocabularyMajorMonotone,
            },
            {
              label: 'Support',
              onClick: () => window.open('mailto:lamqsolutions.dev@gmail.com'),
              icon: QuestionMarkMajorMonotone,
            },
          ]}
        />
      </Navigation>
    </div>
  );
};

export default Menu;
