import React from 'react';

interface ContextProps {
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

const LoadingContext = React.createContext<ContextProps>({
  isLoading: false,
  showLoading: () => {},
  hideLoading: () => {},
});

export default LoadingContext;
