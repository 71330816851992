import { combineReducers } from 'redux'
import undoable from 'redux-undo'
import undoFilter from '../../../utils/undoFilter'
import entities from './entities'
import ui from './ui'

export default combineReducers({
  entities: undoable(entities, {
    filter: undoFilter,
  }),
  ui,
})
