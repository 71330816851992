import React, { useCallback, useContext } from 'react';
import MutationContext from '@/contexts/Mutation';
import fetch from 'unfetch';
import AuthContext from '@/contexts/Auth';
import LoadingContext from '@/contexts/Loading';
import NotificationContext from '@/contexts/Notification';

interface Props {
  children: React.ReactNode;
}

const MutationProvider: React.FC<Props> = ({ children }: Props) => {
  const { logout } = useContext(AuthContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showNotification } = useContext(NotificationContext);

  const mutate = useCallback(
    async (
      url: string,
      data: any,
      method: 'POST' | 'PUT' | 'DELETE' = 'POST',
      hasLoading = true
    ) => {
      if (hasLoading) {
        showLoading();
      }

      let res;
      try {
        res = await fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(data),
        });
      } catch (e) {
        throw new Error('Network error');
      }

      if (hasLoading) {
        hideLoading();
      }

      let jsonRes;

      try {
        jsonRes = await res.json();
      } catch (e) {
        const error = new Error(res.statusText);
        // eslint-disable-next-line
        // @ts-ignore
        error.response = res;

        throw error;
      }

      return jsonRes;
    },
    [showLoading, hideLoading]
  );

  return (
    <MutationContext.Provider value={{ mutate }}>
      {children}
    </MutationContext.Provider>
  );
};

export default MutationProvider;
