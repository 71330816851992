import isEmpty from 'lodash/isEmpty'
import { all, select, takeEvery, put } from 'redux-saga/effects'
import { UPDATE_ENTITIES } from './modules/canvas/editor/entities'
import { selectCanvas, selectScene } from './modules/canvas/editor/ui'

function* handleUpdatedEntities() {
  try {
    const entities = yield select(
      (state) => state.canvas.editor.entities.present
    )

    if (!isEmpty(entities.canvases)) {
      const firstCanvas = entities.canvases[Object.keys(entities.canvases)[0]]

      yield put(selectCanvas(firstCanvas.id))
    }

    if (!isEmpty(entities.scenes)) {
      const firstScene = entities.scenes[Object.keys(entities.scenes)[0]]

      yield put(selectScene(firstScene.id))
    }
  } catch (err) {
    console.log(err)
  }
}

function* rootSaga() {
  yield all([takeEvery(UPDATE_ENTITIES, handleUpdatedEntities)])
}

export default rootSaga
