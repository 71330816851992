import React from 'react';

export type NotificationType = 'info' | 'success' | 'error';

interface ContextProps {
  showNotification: (type: NotificationType, message: string) => void;
}

const NotificationContext = React.createContext<ContextProps>({
  showNotification: () => {},
});

export default NotificationContext;
