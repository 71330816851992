import React, { useCallback, useContext, useMemo } from 'react';
import AuthContext from '@/contexts/Auth';
import useApiShop from '@/hooks/shop/useApiShop';
import useMutateApi from '@/hooks/useMutateApi';
import { Button, Badge } from '@shopify/polaris';
import differenceInDays from 'date-fns/differenceInDays';
import pushEvent from '@/utils/pushEvent';

const PurchaseAction: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { shop } = useApiShop();

  const trialTimeDate = useMemo(() => {
    return shop.trialTime ? new Date(shop.trialTime) : null;
  }, [shop]);

  const isFree = useMemo(() => {
    return shop.subscription !== 'PAID' && trialTimeDate !== null;
  }, [shop, trialTimeDate]);

  const isBeforeTrial = useMemo(() => {
    return shop.subscription !== 'PAID' && trialTimeDate === null;
  }, [shop, trialTimeDate]);

  // TODO: Fix trial days is not sync with trial days in Shopify

  const [isTrial, remainingTrialDays] = useMemo(() => {
    const isTrialDays =
      shop.subscription === 'PAID' &&
      trialTimeDate !== null &&
      !shop.trialEnd &&
      differenceInDays(new Date(), trialTimeDate) <= 14;

    return isTrialDays && trialTimeDate
      ? [true, 14 - differenceInDays(new Date(), trialTimeDate)]
      : [false, 0];
  }, [shop, trialTimeDate]);

  const isPaid = useMemo(() => {
    return (
      shop.subscription === 'PAID' &&
      trialTimeDate !== null &&
      (differenceInDays(new Date(), trialTimeDate) > 14 || shop.trialEnd)
    );
  }, [shop, trialTimeDate]);

  const { isLoading, mutateApi } = useMutateApi('/api/purchase', 'POST');
  const handlePurchase = useCallback(async () => {
    pushEvent('start_free_trial', {price: 9})
    await mutateApi({});
  }, [mutateApi]);

  if (!isLoggedIn || !shop.id) {
    return null;
  }

  return (
    <>
      {isPaid && <Badge status="success">Paid</Badge>}
      {isTrial && (
        <Badge status="success">{`Free Trial ${remainingTrialDays} days left`}</Badge>
      )}
      {isBeforeTrial && (
        <div>
          <Button primary onClick={handlePurchase} loading={isLoading}>
            Start your free trial
          </Button>{' '}
          14 days free, then $9/month
        </div>
      )}
      {isFree && (
        <div>
          <Button primary onClick={handlePurchase} loading={isLoading}>
            Purchase
          </Button>{' '}
          $9/month
        </div>
      )}
    </>
  );
};

export default PurchaseAction;
