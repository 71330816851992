import cloneDeep from 'lodash/cloneDeep';
import dotProp from 'dot-prop-immutable';
import { SETTING_TABS } from '@/constants/canvas';

export const UPDATE = 'canvas/editor/ui/UPDATE';
export const SELECT_CANVAS = 'canvas/editor/ui/SELECT_CANVAS';
export const SELECT_SCENE = 'canvas/editor/ui/SELECT_SCENE';
export const SELECT_LAYER = 'canvas/editor/ui/SELECT_LAYER';
export const CLEAR_SELECTED_LAYER = 'canvas/editor/ui/CLEAR_SELECTED_LAYER';
export const SELECT_SETTING_TAB = 'canvas/editor/ui/SELECT_SETTING_TAB';
export const CLEAR_SETTING_TAB = 'canvas/editor/ui/CLEAR_SETTING_TAB';
export const FOCUS_LAYER_TAB = 'canvas/editor/ui/FOCUS_LAYER_TAB';
export const CLEAR_FOCUSED_LAYER_TAB =
  'canvas/editor/ui/CLEAR_FOCUSED_LAYER_TAB';
export const SELECT_ANIMATION = 'canvas/editor/ui/SELECT_ANIMATION';
export const CLEAR_SELECTED_ANIMATION =
  'canvas/editor/ui/CLEAR_SELECTED_ANIMATION';
export const SELECT_DEVICE = 'canvas/editor/ui/SELECT_DEVICE';
export const UPDATE_PLAYHEAD = 'canvas/editor/ui/UPDATE_PLAYHEAD';
export const RESET_PLAYHEAD = 'canvas/editor/ui/RESET_PLAYHEAD';
export const OPEN_PREVIEW = 'canvas/editor/ui/OPEN_PREVIEW';
export const CLOSE_PREVIEW = 'canvas/editor/ui/CLOSE_PREVIEW';

export const SET_CHANGED = 'canvas/editor/ui/SET_CHANGED';

export const RESET = 'canvas/editor/ui/RESET';

export const UPDATE_SCENE_MOUSE_POSITION =
  'canvas/editor/ui/UPDATE_SCENE_MOUSE_POSITION';

const initialState = {
  device: 'desktop',
  selectedCanvasId: null,
  selectedSceneId: null,
  selectedLayerId: null,
  selectedAnimation: null,
  settingTab: SETTING_TABS.CANVAS,
  layerFocused: null,
  playhead: 0,
  isPreviewOpen: false,
  isChanged: false,
  sceneMouseX: 0,
  sceneMouseY: 0,
  // Increase when selected layer moved by gsap
  selectedLayerMark: 0,

  currentLayerPositionPath: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE: {
      return dotProp.set(state, action.path, action.value)
    }
    case SELECT_CANVAS:
      return {
        ...state,
        selectedCanvasId: action.id,
      };
    case SELECT_SCENE:
      return {
        ...state,
        selectedSceneId: action.id,
      };
    case SELECT_LAYER:
      return {
        ...state,
        selectedLayerId: action.id,
      };
    case CLEAR_SELECTED_LAYER:
      return {
        ...state,
        selectedLayerId: null,
      };
    case SELECT_SETTING_TAB:
      return {
        ...state,
        settingTab: action.tab,
      };
    case CLEAR_SETTING_TAB:
      return {
        ...state,
        settingTab: null,
      };
    case FOCUS_LAYER_TAB:
      return {
        ...state,
        layerFocused: action.tab,
      };
    case CLEAR_FOCUSED_LAYER_TAB:
      return {
        ...state,
        layerFocused: null,
      };
    case SELECT_ANIMATION:
      return {
        ...state,
        selectedAnimation: action.index,
      };
    case CLEAR_SELECTED_ANIMATION:
      return {
        ...state,
        selectedAnimation: null,
      };
    case SELECT_DEVICE:
      return {
        ...state,
        device: action.device,
      };
    case UPDATE_PLAYHEAD:
      return {
        ...state,
        playhead: action.value,
      };
    case RESET_PLAYHEAD:
      return {
        ...state,
        playhead: 0,
      };
    case OPEN_PREVIEW: {
      return {
        ...state,
        isPreviewOpen: true,
      };
    }
    case CLOSE_PREVIEW: {
      return {
        ...state,
        isPreviewOpen: false,
      };
    }
    case SET_CHANGED: {
      return {
        ...state,
        isChanged: action.changed,
      };
    }
    case UPDATE_SCENE_MOUSE_POSITION: {
      return {
        ...state,
        sceneMouseX: action.x,
        sceneMouseY: action.y,
      };
    }
    case RESET:
      return cloneDeep(initialState);
    default:
      return state;
  }
}

export function update(path, value) {
  return { type: UPDATE, path, value };
}

export function selectCanvas(id) {
  return { type: SELECT_CANVAS, id };
}

export function selectScene(id) {
  return { type: SELECT_SCENE, id };
}

export function selectLayer(id) {
  return { type: SELECT_LAYER, id };
}

export function clearSelectedLayer() {
  return { type: CLEAR_SELECTED_LAYER };
}

export function selectSettingTab(tab) {
  return { type: SELECT_SETTING_TAB, tab };
}

export function clearSettingTab() {
  return { type: CLEAR_SETTING_TAB };
}

export function focusLayerTab(tab) {
  return { type: FOCUS_LAYER_TAB, tab };
}

export function clearFocusedLayerTab() {
  return {
    type: CLEAR_FOCUSED_LAYER_TAB,
  };
}

export function selectAnimation(index) {
  return { type: SELECT_ANIMATION, index };
}

export function clearSelectedAnimation() {
  return { type: CLEAR_SELECTED_ANIMATION };
}

export function selectDevice(device) {
  return { type: SELECT_DEVICE, device };
}

export function updatePlayhead(value) {
  return { type: UPDATE_PLAYHEAD, value };
}

export function resetPlayhead() {
  return { type: RESET_PLAYHEAD };
}

export function openPreview() {
  return { type: OPEN_PREVIEW };
}

export function closePreview() {
  return { type: CLOSE_PREVIEW };
}

export function setChanged(changed) {
  return { type: SET_CHANGED, changed };
}

export function updateSceneMousePosition(x, y) {
  return { type: UPDATE_SCENE_MOUSE_POSITION, x, y };
}

export function reset() {
  return { type: RESET };
}
